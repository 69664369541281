import videoInRetail from "./images/video-in-retail.jpg"
import videoInRetailMask from "./images/video-in-retail-mask.png"
import foodWasteAndMarkdowns from "./images/food-waste-and-markdowns.jpg"
import foodWasteAndMarkdownsMask from "./images/food-waste-and-markdowns-mask.png"
import eCommerceLoss from "./images/e-commerce-loss.jpg"
import eCommerceLossMask from "./images/e-commerce-loss-mask.png"
import inventoryAccuracy from "./images/inventory-accuracy.jpg"
import inventoryAccuracyMask from "./images/inventory-accuracy-mask.png"
import onShelfAvailability from "./images/on-shelf-availability.jpg"
import onShelfAvailabilityMask from "./images/on-shelf-availability-mask.png"
import selfCheckout from "./images/self-checkout.jpg"
import selfCheckoutMask from "./images/self-checkout-mask.png"
import rfidInRetail from "./images/rfid-in-retail.jpg"
import rfidInRetailMask from "./images/rfid-in-retail-mask.png"
import totalRetailLoss from "./images/total-retail-loss.jpg"
import totalRetailLossMask from "./images/total-retail-loss-mask.png"

const backgroundList = {
  "video-in-retail": { background: videoInRetail, mask: videoInRetailMask },
  "food-waste-and-markdowns": {
    background: foodWasteAndMarkdowns,
    mask: foodWasteAndMarkdownsMask,
  },
  "ecommerce-loss": { background: eCommerceLoss, mask: eCommerceLossMask },
  "inventory-accuracy": {
    background: inventoryAccuracy,
    mask: inventoryAccuracyMask,
  },
  "on-shelf-availability": {
    background: onShelfAvailability,
    mask: onShelfAvailabilityMask,
  },
  "self-checkout": {
    background: selfCheckout,
    mask: selfCheckoutMask,
  },
  rfid: {
    background: rfidInRetail,
    mask: rfidInRetailMask,
  },
  "retail-loss": {
    background: totalRetailLoss,
    mask: totalRetailLossMask,
  },
}

export { backgroundList }
