import React, { useRef, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

import bgImage from "../../assets/images/supermarket.jpg"

import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay } from "swiper"
import MyLink from "../../utils/checkExternalLink"
import { Icon } from "../Icon"

const BlogsSlider = () => {
  const {
    allPrismicBlog: { edges },
  } = useStaticQuery(graphql`
    {
      allPrismicBlog(
        sort: { order: DESC, fields: first_publication_date }
        limit: 4
      ) {
        edges {
          node {
            id
            uid
            data {
              category {
                uid
                document {
                  ... on PrismicCategory {
                    data {
                      color
                      name
                    }
                  }
                }
              }
              body {
                ... on PrismicBlogDataBodyTextBlock {
                  id
                  primary {
                    content {
                      text
                    }
                    heading {
                      text
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const [activeStep, setActiveStep] = useState(1)

  const swiperRef = useRef()

  const onClickNextSlide = () => {
    swiperRef.current.slideNext()
  }

  const onClickPrevSlide = () => {
    swiperRef.current.slidePrev()
  }

  return (
    <Section>
      <BgImage>
        <picture>
          <img src={bgImage} alt="" />
        </picture>
      </BgImage>
      <Container>
        <ContentWrapper>
          <StyledSwiper
            onSwiper={swiper => (swiperRef.current = swiper)}
            spaceBetween={50}
            modules={[Autoplay]}
            speed={2000}
            loop={true}
            observer={true}
            autoplay={{ delay: 10000, disableOnInteraction: false }}
            onActiveIndexChange={({ realIndex }) =>
              setActiveStep(realIndex + 1)
            }
          >
            {edges.map(
              ({
                node: {
                  id,
                  data: { body },
                  uid,
                },
              }, i) => {
                const root = body[0].primary
                const title = root.heading.text
                const description = root.content.text
                const blogLink = `/blog/${uid}`
                return (
                  <SwiperSlide key={i}>
                    <TextWrapper dataColor={"#fff"}>
                      <h2>
                        <MyLink href={blogLink}>{title}</MyLink>
                      </h2>
                      <SlidersDescription>{description}</SlidersDescription>
                    </TextWrapper>
                  </SwiperSlide>
                )
              }
            )}
            <PaginationWrapper dataColor={"#2A95BF"}>
              <button
                aria-label="previous slide button"
                onClick={onClickPrevSlide}
              >
                <Icon type="SLIDER_ARROW" />
              </button>
              <span className="active">{activeStep}</span>
              <span>of</span>
              <span>{edges.length}</span>
              <button aria-label="next slide button" onClick={onClickNextSlide}>
                <Icon
                  type="SLIDER_ARROW"
                  style={{ transform: "rotate(180deg)" }}
                />
              </button>
            </PaginationWrapper>
          </StyledSwiper>
        </ContentWrapper>
      </Container>
    </Section>
  )
}

export default BlogsSlider

const SlidersDescription = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
`

const StyledSwiper = styled(Swiper)`
  height: 100%;
  padding-bottom: 20px;
  position: relative;
  clip-path: polygon(100% 0, 100% 62%, 95% 100%, 0 100%, 0 0);

  .swiper-slide {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
`
const Section = styled.section`
  height: 200px;
  position: relative;
  box-sizing: border-box;
  margin: 0 auto;
  z-index: 5;

  @media (min-width: ${({ theme }) => theme.media.tablet_min}) {
    height: 400px;
  }

  &::before {
    position: absolute;
    content: "";
    width: 55%;
    height: 105%;
    top: 0;
    left: 0;
    background-color: ${({ theme }) => theme.colors.primary_color};

    @media (min-width: 577px) {
    }
  }
`
const BgImage = styled.div`
  height: 100%;
  max-width: 100%;
  position: absolute;
  display: inline-block;
  z-index: -2;
  right: 0;
  transform: scale(-1, 1);

  @media (min-width: ${({ theme }) => theme.media.wideScreen_xl}) {
    max-width: 45%;
    width: 100%;
  }

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
`
const Container = styled.div`
  ${({ theme }) => theme.container};
  height: 100%;
  display: flex;
  justify-content: space-between;
`
const ContentWrapper = styled.div`
  height: 100%;
  max-width: 70%;
  position: relative;

  @media (min-width: ${({ theme }) => theme.media.wideScreen_max}) {
    max-width: 72%;
  }
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 11%;
    width: 100%;
    height: 105%;
    background-color: ${({ theme }) => theme.colors.primary_color};
    clip-path: polygon(0 0, 100% 0, 84% 100%, 0% 100%);
  }
`

const TextWrapper = styled.div`
  width: 100%;
  padding: 15px 0;
  position: relative;
  overflow: hidden;

  @media (min-width: ${({ theme }) => theme.media.tablet_min}) {
    padding: 25px 0;
  }

  h2 {
    margin: 0 0 5px 0;
    font-size: 16px;
    line-height: 19px;
    color: ${({ theme }) => theme.colors.secondary_color};
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;

    @media (min-width: ${({ theme }) => theme.media.tablet_min}) {
      font-size: 30px;
      line-height: 32px;
      margin: 0 0 15px 0;
    }
    @media (min-width: ${({ theme }) => theme.media.desktop_min}) {
      font-size: 36px;
      line-height: 43px;
    }

    a {
      transition: 0.4s ease;
      &:hover {
        color: ${({ theme }) => theme.colors.third_color};
      }
    }
  }

  p {
    margin: 0;
    color: white;
    font-size: 14px;
    line-height: 19px;
    @media (max-width: ${({ theme }) => theme.media.wideScreen_xl}) {
      max-width: 95%;
    }

    @media (min-width: ${({ theme }) => theme.media.tablet_min}) {
      font-size: 18px;
      line-height: 26px;
      max-height: initial;
    }
    @media (min-width: ${({ theme }) => theme.media.desktop_min}) {
      font-size: 20px;
      line-height: 32px;
    }
  }
`

const PaginationWrapper = styled.div`
  position: absolute;
  left: 4px;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 15.5px;

    @media (min-width: ${({ theme }) => theme.media.tablet_min}) {
      font-size: 22px;
    }

    &.active {
      width: 22px;
      height: 22px;
      background: ${({ theme }) => theme.colors.secondary_color};
      border-radius: 50%;

      @media (min-width: ${({ theme }) => theme.media.tablet_min}) {
        width: 36px;
        height: 36px;
      }
    }
  }

  button {
    background: transparent;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 10px;
      height: 16px;
      stroke: white;
      transition: 0.4s;

      @media (min-width: ${({ theme }) => theme.media.tablet_min}) {
        width: 18px;
        height: 36px;
      }
    }

    &:hover {
      svg {
        stroke: ${({ theme }) => theme.colors.secondary_color};
      }
    }
  }
`
