import React from "react"
import { MainBackground } from "../MainBackground"
import { Container } from "../Container"
import { useStaticQuery, graphql } from "gatsby"
import FocusAreaList from "./focusAreaList"

import styled from "styled-components"
import MainTitle from "../CommonComponents/MainTitle";

export const FocusArea = () => {
  const {
    prismicHomePage: { data },
  } = useStaticQuery(graphql`
    query {
      prismicHomePage {
        data {
          body {
            ... on PrismicHomePageDataBodyFocusAreasGrid {
              id
              items {
                focus_area {
                  document {
                    ... on PrismicCategory {
                      id
                      data {
                        color
                        name
                      }
                      uid
                    }
                  }
                }
              }
              primary {
                heading {
                  text
                }
                subheading
              }
            }
          }
        }
      }
    }
  `)

  const [objData] = data.body

  return (
    <section id="#focusAreas">
      <MainBackground container sectionContainer>
        <Container>
          <MainTitle variant="h2">{objData.primary.heading.text}</MainTitle>
          <Subtitle>{objData.primary.subheading}</Subtitle>
          <FocusAreaList list={objData.items} />
          <ButtonWrapper>
            <Button
              as="a"
              variant="primary"
              href="https://ecr-shrink-group.us17.list-manage.com/subscribe?u=6023bad92de17c3cdf8bb689d&id=fe6105ee15"
              target="_blank"
              rel="noopener noreferrer"
            >
              Subscribe
            </Button>
          </ButtonWrapper>
        </Container>
      </MainBackground>
    </section>
  )
}

const Subtitle = styled.p`
  ${({ theme }) => theme.paragraphs.p};
  font-weight: 500;

  @media (min-width: ${({ theme }) => theme.media.tablet_min}) {
    max-width: 92%;
  }

  @media (min-width: ${({ theme }) => theme.media.wideScreen_xl}) {
    max-width: 100%;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  max-width: max-content;
  margin: 0 auto;
  box-shadow: ${({theme}) => theme.box.boxShadow};
`

const Button = styled.a`
  ${({ theme }) => theme.primaryButton};

  @media (min-width: ${({ theme }) => theme.media.desktop_min}) {
    padding: 13px 78px;
  }
`
